import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { DOMParser } from "xmldom";
import SEO from "../components/seo";
import Breadcrumb from "../components/breadcrumb.js";
const dictionary = require("../dic/dic.json");

class GlossaryTemplate extends Component {
  constructor (props) {
    super(props);
    this.createSchema = this.createSchema.bind(this);
  }

  componentDidMount () {
    // delete  Te recomendamos
    const teRecomendamos = document.getElementsByClassName("rp4wp-related-posts");
    while (teRecomendamos.length > 0) {
      teRecomendamos[0].parentNode.removeChild(teRecomendamos[0]);
    }
    // Fin delete Te recomendamos
    const relatedPostsWPML = document.querySelectorAll(".rp4wp-related-post-content a");
    relatedPostsWPML.forEach(link => {
      const href = link.getAttribute("href");
      if (href.slice(-1) === "/") {
        link.setAttribute("href", href.substring(0, href.length - 1));
      }
    });
  }

  createQuestion (node) {
    let nextNode = node.nextSibling;
    if (nextNode.nodeName === "#text") nextNode = nextNode.nextSibling;
    let nextNextNode = nextNode.nextSibling; //Checking for Lists
    if (nextNextNode.nodeName === "#text") nextNextNode = nextNextNode.nextSibling;
    if (nextNode.tagName !== "p") return null;
    if (nextNode.textContent.trim() === "") return null;
    let answer = nextNode.textContent;
    if (nextNextNode.tagName === "ul") {
      answer += "\n" + nextNextNode.textContent;
    }
    const question = {
      "@type": "Question",
      name: node.textContent,
      acceptedAnswer: {
        "@type": "Answer",
        text: answer
      }
    };
    return question;
  }

  createSchema (post) {
    const appJsonAux = [];
    if (typeof post.content !== "undefined" && post.content !== null) {
      const parser = new DOMParser();
      const domAux = parser.parseFromString(post.content, "text/html");
      let questions = [];
      const nodeList = domAux.childNodes;
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i];
        if (node.tagName !== "h2") continue;
        const question = this.createQuestion(node);
        if (question !== null) questions.push(question);
      }
      if (questions.length > 0) {
        const faqsSchema = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: questions
        };
        appJsonAux.push(JSON.stringify(faqsSchema));
      }
    }
    console.log("JSON", appJsonAux);

    return appJsonAux;
  }

  render() {
    const post = this.props.data.wpPost;
    const seo = post.seo;
    const lang = this.props.pageContext.idioma;
    const langPage = this.props.pageContext.idioma;
    let app_json = this.createSchema(post);
    var breadcrumb = [];
    var categoria_principal = "Glosario";
    post.categories.nodes.forEach((categoria) => {
      if (categoria.ancestors !== undefined && categoria.ancestors !== null) {
        categoria_principal = categoria.ancestors.nodes[0].name;
      }
    });
    let page = {
      title: categoria_principal,
      slug: "/.."
    }
    breadcrumb.push(page);
    page = {
      title: post.title,
      slug: null
    }
    breadcrumb.push(page);
    var slugPage = post.uri;


    // {slugPage.split("/").length==3 ? slugPage=post.uri.substring(0,post.uri.length-1) : slugPage=slugPage}
    var openGraphslugPage = seo.opengraphUrl;
    if (slugPage !== undefined) {
      slugPage = slugPage.replace("https://www.getbillage.com/wp", "")
      slugPage = slugPage.replace("https://getbillage.com/wp", "")
      slugPage = slugPage.replace("https://www.getbillage.com", "")
      slugPage = slugPage.replace("http://www.getbillage.com/wp", "")
      slugPage = slugPage.replace("http://getbillage.com/wp", "")
      slugPage = slugPage.replace("http://www.getbillage.com", "")
    }
    slugPage = "/" + lang + "/" + slugPage.substring(4, slugPage.length);
    openGraphslugPage = "/" + lang + "/" + openGraphslugPage.substring(4, openGraphslugPage.length);

    const alternates = [slugPage];
    // {openGraphslugPage.split("/").length==3 ? openGraphslugPage=post.uri.substring(0,post.uri.length-1) : openGraphslugPage=openGraphslugPage}
    const nodepostCat = post.categories.nodes
    // var categoria = null
    // var main_cat_slug = null // not used
    for (var i in nodepostCat) {
      if (
        nodepostCat[i].ancestors !== undefined &&
        nodepostCat[i].ancestors !== null
      ) {
        // categoria = nodepostCat[i]
        //console.log("XXXXXXXXXX"+categoria)
      } else {
        // if(nodepostCat[i].databaseId==102 || nodepostCat[i].databaseId==99){
        //main_cat_slug = nodepostCat[i].slug // not used
        // }
      }
    }
    var relacionados =
      this.props.data.wpPost.relacionadosGroup.relacionados !== null
        ? this.props.data.wpPost.relacionadosGroup.relacionados
        : []
    var relacionadosImportados = this.props.data.allWpPost.edges
    for (i in relacionadosImportados) {
      relacionados.push(relacionadosImportados[i].node)
    }
    return (
      <Layout
        currentPage={post.title}
        langPage={this.props.pageContext.idioma}
        alternates={alternates}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugPage}
          appJson={app_json}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={post.date}
          dctermsModified={post.modified}
          lang={langPage.substring(0, 2)}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
        />

        <>
          <Breadcrumb props={breadcrumb} isChild={"false"} isPage="false" />
          <div className="container glosario-ficha">
            <div className="row">
              <div className="col-8">
                <Link to="../" className="link-back">
                  « Volver al glosario
                </Link>
                <section className="title">
                  <h1
                    dangerouslySetInnerHTML={{ __html: post.title }}
                    className="posttitle"
                  />
                  <hr />
                </section>

                <div
                  dangerouslySetInnerHTML={{ __html: post.content }}
                  className="postbody"
                />
              </div>
              <div className="col-4 columnalatpost">
                <div className="sidebar">
                  <div className="widget main_navigation">
                    <Link
                      to="https://www.getbillage.com/es/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        title="Programa de facturación online"
                        src="/images/cta-lateral.png"
                        alt="Programa de facturación online"
                      />
                    </Link>
                  </div>
                  <section className="widget relatedposts glosariorel">
                    {relacionados.length > 0 ? (
                      <h3 className="title-section">
                        {
                          dictionary[this.props.pageContext.idioma]
                            .title_glosario_relacionados
                        }
                      </h3>
                    ) : null}
                    {relacionados.length > 0
                      ? relacionados.map(relacionado => {
                        //saber cual es la categoria padre para utilizar en url
                        //   const nodeRel = relacionado.categories.nodes
                        //   var categoria
                        //   for (var i in nodeRel) {
                        //     if (nodeRel[i].ancestors !==null) {
                        //       categoria = nodeRel[i].ancestors.nodes[0].slug
                        //     }
                        //   }
                        //console.log("relacionados", relacionado)
                        let urlRelacionado=relacionado.uri;
                        if (
                          urlRelacionado.charAt(urlRelacionado.length - 1) === "/"
                        ) {
                          urlRelacionado = urlRelacionado.substring(0,  urlRelacionado.length - 1);
                        }

                        return (
                          <article>
                            <span className="title">
                              <Link
                                to={`${urlRelacionado}`}
                              >{`${relacionado.title}`}</Link>
                            </span>
                          </article>
                        )
                      })
                      : null}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </>
      </Layout>
    )
  }
}

export default GlossaryTemplate

export const pageQuery = graphql`
  query currentGlossaryQuery($id: String!, $relatedImportedPosts: [Int]!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      modified
      uri
      slug
      author {
        node {
          name
          slug
          avatar {
            url
          }
        }
      }
      categories {
        nodes {
          databaseId
          name
          slug
          ancestors {
            nodes {
              slug
              name
            }
          }
        }
      }
      relacionadosGroup {
        relacionados {
          ... on WpPost {
            id
            title
            slug
            uri
            categories {
              nodes {
                slug
                databaseId
                ancestors {
                  nodes {
                    slug
                  }
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
    }
    allWpPost(filter: { databaseId: { in: $relatedImportedPosts } }) {
      edges {
        node {
          id
          title
          slug
          uri
          featuredImage {
            node {
              srcSet
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            nodes {
              databaseId
              name
              slug
              ancestors {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
